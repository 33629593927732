<template>
  <v-container>
    <v-row>
      <v-col class="pa-0">
        <v-card class="pa-10">
          <v-card-title>
            Users
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="users"
          >
            <template #item.photo="{ item }">
              <router-link :to="`/profile/${item.address}`">
                <v-avatar size="64">
                  <v-img max-width="64" max-height="64" :src="mainHost + `/avatar?addr=` + item.address" />
                </v-avatar>
              </router-link>
            </template>
            <template #item.discord="{ item }">
              <span v-if="!item.profiles || !item.profiles.discord" />
              <span v-else>
                {{ item.profiles.discord.username }} #{{ item.profiles.discord.discriminator }}
              </span>
            </template>
            <template #item.twitch="{ item }">
              <span v-if="!item.profiles || !item.profiles.twitch" />
              <span v-else>
                {{ item.profiles.twitch.login }} - <span @click="openStream(item)">{{ item.ttvOnline ? 'ON' : 'OFF' }}</span>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row v-for="y,addr of profiles" :key="addr">
      <router-link :to="`/profile/${addr}`">
        {{ y.display }} - {{ addr }} - 0 GGT<br>
        <v-img max-width="64" max-height="64" :src="mainHost + `/avatar?addr=` + addr" />
      </router-link>
    </v-row> -->
  </v-container>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapState,
  } from 'vuex'
  export default {
    name: 'Streams',
    data () {
      return {
        search: '',
        headers: [
          {
            width: '0px',
            text: '',
            align: 'start',
            sortable: false,
            value: 'photo',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'display',
          },
          { text: 'Wallet', value: 'address' },
          { text: 'Discord', value: 'discord' },
          { text: 'Twitch', value: 'twitch' },
          { text: 'Lol', value: 'profiles.lol.name' },
          { text: 'Axies', value: 'profiles.axie.total' },
          // { text: 'Balance', value: 'balance' },
          // { text: 'Games', value: 'games' },
          // { text: 'Wins', value: 'wins' },
        ],
      }
    },
    computed: {
      ...mapState('profiles', ['profiles']),
      ...mapState('platform', ['mainHost']),
      ...mapState('node', ['nodes', 'invites', 'curRegion', 'featured', 'streams']),
      ...mapGetters('node', ['node']),
      users () {
        var ret = Object.values(this.profiles)
        var n = 0
        for (var x of Object.keys(this.profiles)) {
          ret[n++].address = x
        }
        return ret
      },
    },
    watch: {
    },
    async mounted () {
      this.getProfiles()
    },
    methods: {
      ...mapActions('profiles', ['getProfiles']),
      ...mapActions('node', ['setNode', 'grabStreams']),
      openStream (item) {
        console.log('os', item)
        this.$router.push('stream/' + item.profiles.twitch.login)
      },
    },
  }
</script>
